import {
  DatePicker,
  Dropdown,
  IDropdownOption,
  Label,
  Stack,
  TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { RenderIf } from "../libs";

export interface IField {
  name: string;
  fieldType: string;
  label: string;
  lookupList?: string;
  multiSelect?: boolean;
}
interface ILookupValue {
  key: string;
  value: string;
}
interface ILookup {
  name: string;
  values: ILookupValue[];
}
interface IValidation {
  field: string;
  rules: string[];
}
export interface IMetadata {
  lookups: ILookup[];
  fields: IField[];
  validations: IValidation[];
}

export interface IOtherInfoProps {
  metadata: IMetadata;
  values?: string;
  otherInfo?: string;
  isSaved: React.MutableRefObject<boolean>;
  onOtherInfoChanged: (newValue: string) => void;
  readOnly?: boolean;
}

export const OtherInfo = (params: IOtherInfoProps) => {
  const [metadata] = useState<IMetadata>(params.metadata);
  const [values, setValues] = useState();

  const fetchData = async () => {
    if (params.values && params.values !== "") {
      setValues(JSON.parse(params.values ?? ""));
    }
  };

  useEffect(() => {
    fetchData();
  }, [params]);

  const getLookupOption = (
    metadata: IMetadata,
    field: IField
  ): IDropdownOption<any>[] => {
    if (params.readOnly && params.otherInfo) {
      const otherInfoObj: any = JSON.parse(params.otherInfo);
      const val = otherInfoObj[field.name as keyof typeof otherInfoObj];
      const valParts = val.toString().split(":");
      return [{ key: val.toString(), text: valParts[1] }];
    }
    const lookup = metadata.lookups.filter(
      (l) => l.name === field.lookupList
    )[0];
    if (lookup) {
      const lookupValues = lookup.values;
      return lookupValues.map((v) => ({ key: v.key, text: v.value }));
    }
    return [];
  };

  const labelColumnStyle = {
    width: 125,
    textAlign: "left" as const,
  };
  const valueColumnStyle = {
    width: "auto",
  };

  const [otherInfo, setOtherInfo] = useState(params.otherInfo);

  const handleOtherInfoChange = (fieldName: string, value?: string) => {
    if (value !== undefined) {
      setOtherInfo((prev: any) => {
        const newOtherInfo = prev ? JSON.parse(prev) : {};
        newOtherInfo[fieldName] = value;
        params.onOtherInfoChanged(newOtherInfo);
        return JSON.stringify(newOtherInfo);
      });
    }
  };

  return (
    <>
      {!metadata || !values ? (
        <></>
      ) : (
        metadata.fields.map((field) =>
          values[field.name] ? (
            <Stack horizontal>
              <Label style={labelColumnStyle}>{field.label}</Label>
              <RenderIf
                condition={
                  field.fieldType === "Number" || field.fieldType === "String"
                }
              >
                <TextField
                  style={valueColumnStyle}
                  onChange={(_, newValue) =>
                    handleOtherInfoChange(field.name, newValue)
                  }
                  type={field.fieldType === "Number" ? "number" : "string"}
                  value={JSON.parse(otherInfo ?? "")[field.name]}
                  readOnly={params.isSaved.current}
                  disabled={params.readOnly}
                />
              </RenderIf>
              <RenderIf
                condition={
                  field.fieldType === "Lookup" ||
                  field.fieldType === "AutoComplete"
                }
              >
                <Dropdown
                  styles={{ root: { valueColumnStyle } }}
                  options={getLookupOption(params.metadata, field)}
                  multiSelect={field.multiSelect}
                  selectedKey={
                    field.multiSelect
                      ? undefined
                      : JSON.parse(otherInfo ?? "")[field.name]
                  }
                  selectedKeys={
                    !!field.multiSelect
                      ? (JSON.parse(otherInfo ?? "")[field.name] ?? "|").split(
                          "|"
                        )
                      : undefined
                  }
                  onChange={(_, option) => {
                    let value = option?.key.toString();
                    if (field.multiSelect) {
                      const currKeys: string[] = (
                        JSON.parse(otherInfo ?? "")[field.name] ?? "|"
                      )
                        .split("|")
                        .filter((part: string) => part);
                      if (option?.selected) {
                        currKeys.push(option?.key.toString());
                        value = currKeys.join("|");
                      } else {
                        value = currKeys
                          .filter((part: string) => part !== value)
                          .join("|");
                      }
                    }
                    handleOtherInfoChange(field.name, value);
                  }}
                  disabled={params.readOnly || params.isSaved.current}
                />
              </RenderIf>

              <RenderIf condition={field.fieldType === "Date"}>
                <DatePicker
                  style={valueColumnStyle}
                  placeholder={
                    otherInfo && JSON.parse(otherInfo)[field.name]
                      ? new Date(
                          JSON.parse(otherInfo)[field.name]
                        ).toDateString()
                      : ""
                  }
                  ariaLabel="Select a date"
                  isMonthPickerVisible={false}
                  onSelectDate={(selectedDate: Date | null | undefined) => {
                    handleOtherInfoChange(
                      field.name,
                      selectedDate?.toISOString()
                    );
                  }}
                  disabled={params.readOnly || params.isSaved.current}
                />
              </RenderIf>
            </Stack>
          ) : (
            <></>
          )
        )
      )}
    </>
  );
};
