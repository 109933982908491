import { useParams, useSearchParams } from "react-router-dom";
import ManualIdEntry from './ManualIdEntry';
import Capture from './Capture'
import { Stack, Text } from "@fluentui/react";

const Home = () => {
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const code: string = searchParams.get("code") || '';
  const zone: string | null = searchParams.get("zone");
  const tooManyRequests: string | null = searchParams.get("tooManyRequests");

  return (
    (tooManyRequests === '') ?
      (
        <Stack horizontalAlign="center">
          <Text variant="xLarge">Too many attempt</Text>
          <Text>You have reached the number of issues you can report in 5 mins, please try again later</Text>
        </Stack>
      ) :
      (!id && !code && !zone) ? (
        <ManualIdEntry />
      ) : (
        <Capture zone={zone} />
      )
  )
}

export default Home;