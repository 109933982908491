import { Stack, Label, TextField, Dropdown, IDropdownOption } from '@fluentui/react';
import { useEffect, useState } from 'react'
import { IField, IMetadata } from './OtherInfo';

export interface IIssueOtherQuestionsProps {
  metadataResponse?: any;
  onOtherInfoChanged: (newValue: string) => void;
}

const IssueOtherQuestions = (params: IIssueOtherQuestionsProps) => {
  const [metadata, setMetadata] = useState<IMetadata>();
  const [, setOtherInfo] = useState("");

  const fetchData = async () => {
    if (!params.metadataResponse) {
      return;
    }
    const metadata = params.metadataResponse as IMetadata;
    console.log(metadata);
    setMetadata(metadata);
  }

  useEffect(() => {
    fetchData();
  }, [params]);

  const getLookupOption = (metadata: IMetadata, field: IField): IDropdownOption<any>[] => {
    const lookup = metadata.lookups.filter(l => l.name === field.lookupList)[0];
    if (lookup) {
      const lookupValues = lookup.values;
      return lookupValues.map(v => ({ key: v.key, text: v.value }));
    }
    return [];
  }

  const handleOtherInfoChange = (fieldName: string, value?: string) => {
    if (value) {
      setOtherInfo((prev: any) => {
        const newOtherInfo = { ...prev };
        newOtherInfo[fieldName] = value;
        params.onOtherInfoChanged(newOtherInfo);
        return newOtherInfo;
      });
    }
  };

  const labelColumnStyle = {
    // width: 125,
    textAlign: 'left' as const
  };

  const valueColumnStyle = {
    width: 'auto',
  }

  return (
    <>
      {(!metadata) ? <></> : metadata.fields.map(field =>
        <Stack>
          <Label style={labelColumnStyle}>{field.label}</Label>
          {(field.fieldType === "Number" || field.fieldType === "String") ?
            <TextField styles={{ root: { width: '100%' } }}
              type={field.fieldType === "Number" ? "number" : "string"}
              onChange={(_, newValue) => handleOtherInfoChange(field.name, newValue)}
            />
            : (field.fieldType === "Lookup") ?
              <Dropdown style={valueColumnStyle} options={getLookupOption(metadata, field)}
                onChange={(_, option) => handleOtherInfoChange(field.name, option?.key.toString())}
              />
              : <></>}
        </Stack>
      )}
    </>
  )
}

export default IssueOtherQuestions