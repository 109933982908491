import { IconButton, mergeStyles, Modal, Slider, Stack } from '@fluentui/react'
import { useState } from 'react';


export interface ICaptchaDialogParams {
  isModalOpen: boolean;
  hideModal: any;
  onSolved: any;
}

const puzzlePieceTop = Math.round(34 + (68 * Math.random())); // 34- 102
const puzzlePieceLeft = Math.round(185 * Math.random()); // 0 -185
const CaptchaDialog = (params: ICaptchaDialogParams) => {

  const [sliderPos, setSliderPos] = useState(20);
  const [isSolved, setIsSolved] = useState(false);
  const stackTokens = { childrenGap: 5 };

  interface SvgParams {
    x: number;
    y: number;
  }
  const Svg = (params: SvgParams) => (
    <svg
      width="70"
      height="110"
      viewBox="0 0 70 110"
      version="1.1"
      id="svg5">
      <defs id="defs2">
        <pattern id="img1" patternUnits="userSpaceOnUse" width="260" height="160" x={params.x} y={params.y}>
          <image href="PuzzleImage260x160.png" x="0" y="0" />
        </pattern>
      </defs>
      <g
        id="layer1"
        transform="translate(-149.0425,-275.70859)">
        <path
          id="path4665"
          style={{ stroke: '#0f0f0f' }}
          fill="url(#img1)"
          d="m 172.00685,345.5983 c 2.9345,-0.10743 5.8265,3.0297 4.2363,5.6974 -1.3922,2.3355 -2.939,4.7579 -2.1324,7.6561 0.78521,2.8216 3.2862,4.8967 6.2829,4.8967 2.9967,0 5.4974,-2.0802 6.2829,-4.9025 0.80687,-2.899 -0.74023,-5.3149 -2.1324,-7.6504 -1.5903,-2.6677 1.3018,-5.8049 4.2362,-5.6974 6.6638,0.24396 13.191,1.8264 19.772,2.7738 0.93465,-6.6276 2.5107,-13.201 2.7527,-19.912 0.10661,-2.957 -3.0066,-5.8712 -5.654,-4.2688 -2.3177,1.4029 -4.7216,2.9615 -7.5978,2.1488 -2.8001,-0.79125 -4.8594,-3.3115 -4.8594,-6.3311 0,-3.0197 2.0643,-5.5396 4.8651,-6.3311 2.8769,-0.81309 5.2744,0.7459 7.5921,2.1488 2.6474,1.6025 5.7606,-1.3118 5.654,-4.2688 -0.2421,-6.715 -1.8125,-13.292 -2.7498,-19.927 -6.5838,0.94452 -13.111,2.5327 -19.775,2.7767 -2.9345,0.10742 -5.8265,-3.0297 -4.2362,-5.6974 1.3922,-2.3355 2.9393,-4.7572 2.1324,-7.6561 -0.78556,-2.8223 -3.2862,-4.9025 -6.2829,-4.9025 -2.9967,0 -5.4977,2.0809 -6.2829,4.9025 -0.80657,2.8982 0.74022,5.3206 2.1324,7.6561 1.5902,2.6677 -1.3018,5.8049 -4.2363,5.6974 -6.6601,-0.24383 -13.183,-1.832 -19.763,-2.7767 -0.93733,6.6343 -2.5135,13.212 -2.7556,19.927 -0.10662,2.957 3.0066,5.8712 5.654,4.2688 2.3177,-1.4029 4.7209,-2.9619 7.5978,-2.1488 2.8008,0.79157 4.8651,3.3115 4.8651,6.3311 0,3.0197 -2.065,5.5399 -4.8651,6.3311 -2.8762,0.81275 -5.2801,-0.7459 -7.5978,-2.1488 -2.6474,-1.6025 -5.7607,1.3118 -5.654,4.2688 0.24197,6.7112 1.818,13.285 2.7556,19.915 6.58,-0.94471 13.103,-2.5329 19.763,-2.7767 z" />
      </g>
    </svg>
  )
  const disabledOkButtonClass = mergeStyles({
    borderRadius: '50%',
  });

  const enabledOkButtonClass = mergeStyles({
    backgroundColor: 'lightyellow',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: 'green'
  });

  return (
    <Modal
      isOpen={params.isModalOpen}
      onDismiss={params.hideModal}
      isBlocking={true}
    >
      {" "}
      <div>
      </div>
      <Stack style={{ padding: 10 }} tokens={stackTokens}>
        <Stack.Item align="start">
          <span>Are you human?</span>
        </Stack.Item>
        <Stack.Item align="center" >
          <img src="PuzzleImage260x160.png" alt="PuzzleImage" style={{ width: 260, height: 160 }} />
          <div style={{ position: "absolute", float: "left", left: sliderPos, top: puzzlePieceTop, zIndex: 1000 }}>
            <Svg x={145 - puzzlePieceLeft} y={250 - puzzlePieceTop + 60} />
          </div>
          {/* svg y=180 -> dev top 125 */}
          {/* svg y=170 -> dev top 135 */}
          {/* svg y=150 -> dev top 155 */}
          {/* x=-45 value=220*/}
          {/* x= 45 value=120*/}
        </Stack.Item>
        <Stack.Item align="stretch" >
          <Stack horizontal>
            <Stack.Item align="stretch" style={{ width: 260 }}>
              <Slider
                min={0} max={220} step={5} defaultValue={sliderPos} showValue={false} snapToStep
                onChange={(value) => {
                  setSliderPos(value);
                  setIsSolved(value >= puzzlePieceLeft + 25 && value <= puzzlePieceLeft + 35);
                }}
              />
            </Stack.Item>
            <IconButton iconProps={{ iconName: 'SkypeCheck' }}
              disabled={!isSolved}
              className={isSolved ? enabledOkButtonClass : disabledOkButtonClass}
              onClick={() => { params.onSolved(); params.hideModal(); }}
            />
          </Stack>
        </Stack.Item>
      </Stack>
    </Modal >
  )
}

export default CaptchaDialog;