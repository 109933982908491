import { IStackStyles } from "@fluentui/react";

export const stackTokens = { childrenGap: 10 };

export const stackStyles: Partial<IStackStyles> = {
  root: {
    width: 320,
    padding: 10,
    paddingTop: 5
  },
};
