import { ActionButton, List, Stack, mergeStyleSets } from "@fluentui/react";
import React from "react";

interface ICameralRollProps {
  images: string[];
  onRetake: (index: number) => void;
}

const CameralRoll = (params: ICameralRollProps) => {
  const classNames = mergeStyleSets({
    listGridExample: {
      overflow: "hidden",
      fontSize: 0,
      position: "relative",
    },
    listGridExampleTile: {
      textAlign: "center",
      outline: "none",
      position: "relative",
      float: "left",
      selectors: {
        "focus:after": {
          content: "",
          position: "absolute",
          left: 2,
          right: 2,
          top: 2,
          bottom: 2,
          boxSizing: "border-box",
        },
      },
    },
    listGridExampleSizer: {
      paddingBottom: "100%",
    },
    listGridExamplePadder: {
      position: "absolute",
      left: 2,
      top: 2,
      right: 2,
      bottom: 2,
    },
    listGridExampleLabel: {
      background: "rgba(0, 0, 0, 0.3)",
      color: "#FFFFFF",
      position: "absolute",
      padding: 10,
      bottom: 0,
      left: 0,
      width: "100%",
      boxSizing: "border-box",
    },
    listGridExampleImage: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
    },
  });

  const onRenderCell = React.useCallback(
    (image?: string, index?: number | undefined) => {
      return (
        <div
          className={classNames.listGridExampleTile}
          data-is-focusable
          style={{
            width: 100 / 5 + "%",
          }}
        >
          <div className={classNames.listGridExampleSizer}>
            <div className={classNames.listGridExamplePadder}>
              <Stack horizontalAlign="center">
                <img
                  src={image}
                  alt=""
                  className={classNames.listGridExampleImage}
                />
                <Stack horizontal>
                  <ActionButton
                    iconProps={{ iconName: "Undo" }}
                    onClick={() => {
                      params.onRetake(index ?? 0);
                    }}
                  >
                    Retake
                  </ActionButton>
                </Stack>
              </Stack>
              <span
                className={classNames.listGridExampleLabel}
              >{`item ${index}`}</span>
            </div>
          </div>
        </div>
      );
    },
    []
  );
  return (
    <>
      <List
        items={params.images}
        onRenderCell={onRenderCell}
        getPageHeight={() => 1}
      />
    </>
  );
};

export default CameralRoll;
