import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import flagEN from './en/flag.svg';
import translationDE from './de/translation.json';
import flagDE from './de/flag.svg';
import translationCN from './cn/translation.json';
import flagCN from './cn/flag.svg';
// source: https://github.com/hampusborgos/country-flags

export const resources = {
  en: {
    translation: translationEN,
    flag: flagEN,
  },
  de: {
    translation: translationDE,
    flag: flagDE,
  },
  cn: {
    translation: translationCN,
    flag: flagCN,
  },
};

i18next.use(initReactI18next).init({
  lng: 'en', // if you're using a language detector, do not define the lng option
  debug: true,
  resources,
});