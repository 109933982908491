import { ITextField, PrimaryButton, Stack, TextField } from '@fluentui/react';
import { useRef } from 'react'

const ManualIdEntry = () => {
  const manuallyEnteredId = useRef(null);

  return (
    <Stack horizontal horizontalAlign="center">
      <Stack
        horizontal
        verticalAlign="center"
        tokens={{
          childrenGap: 5,
        }}
      >
        <span>Enter the Id: </span>
        <TextField
          type="numeric"
          required={true}
          componentRef={manuallyEnteredId}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              event.preventDefault();
              document.getElementById("loadButton")?.click();
            }
          }}
        />
        <PrimaryButton
          id="loadButton"
          text="load"
          onClick={() => {
            const enteredId = `${(manuallyEnteredId?.current as unknown as ITextField).value
              }`;
            const url = `${window.location.href}${enteredId}`;
            window.location.replace(url);
          }}
        />
      </Stack>
    </Stack>
  )
}

export default ManualIdEntry