import { DefaultButton, IconButton, IIconProps, IStackStyles, MessageBar, MessageBarType, Separator, Stack, Text } from '@fluentui/react';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { stackStyles, stackTokens } from '../common/styles/StackStyles';
import { RenderIf } from '../libs';

const Success = () => {
  const [searchParams] = useSearchParams();
  const issue: string = searchParams.get("issue") || '';
  const code: string = searchParams.get("asset") || '';
  const zone: string = searchParams.get("zone") || '';

  const navigate = useNavigate();
  const likeIcon: IIconProps = {
    iconName: 'Like',
  }
  const dislikeIcon: IIconProps = {
    iconName: 'Dislike',
  }
  interface IFeedbackResponse {
    feedbackId: number
  }
  const [status, setStatus] = useState("");

  const submitFeedback = (feedback: 'like' | 'dislike') => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ issueUuid: issue, description: feedback }),
    };
    fetch(
      `${process.env.REACT_APP_BACKEND_API_URL}/v1/feedback?code=${process.env.REACT_APP_BACKEND_API_KEY}`,
      requestOptions
    )
      .then((response: any) => {
        if (!response.ok) {
          throw response.statusText;
        }
        return response.json();
      })
      .then(
        (data: IFeedbackResponse) => {
          setStatus(`Thanks for sharing your feedback`);
          console.log(data.feedbackId);
        },
        (reason) => {
          setStatus(`Thanks for sharing your feedback.`);
          console.log(reason);
        }
      )
      .finally(() => { });
  }

  return (
    <Stack horizontal horizontalAlign="center">
      <Stack tokens={stackTokens} styles={stackStyles}>
        <img src={process.env.REACT_APP_LOGO_URL} alt="Logo" className="App-logo" />
        <Text variant='xLarge'>Success</Text>
        <MessageBar messageBarType={MessageBarType.success}>
          Issue created successfully: {issue}
        </MessageBar>
        <Separator></Separator>
        <RenderIf condition={!status}>
          <Stack horizontal>
            <Text variant='medium'>How did we do today</Text>
            <IconButton
              iconProps={likeIcon}
              title="Like" ariaLabel='Like'
              onClick={() => submitFeedback('like')} />
            <IconButton
              iconProps={dislikeIcon}
              title="Dislike" ariaLabel='Dislike'
              onClick={() => submitFeedback('dislike')} />
          </Stack>
        </RenderIf>
        <RenderIf condition={!!status}>
          <Text variant='xLarge'>{status}</Text>
        </RenderIf>
        <DefaultButton
          onClick={() => navigate(`${zone ? `/?zone=${zone}` : `/?code=${code}`}`)}>
          Report another issue
        </DefaultButton>
      </Stack>
    </Stack>
  )
}

export default Success