import {
  Stack,
  Toggle,
  ActionButton,
  IconButton,
  IStackProps,
} from "@fluentui/react";
import React, { useState } from "react";
import Webcam from "react-webcam";
import { RenderIf } from "../libs";

interface IVideoConstraints {
  width: number;
  height: number;
  facingMode: string | { exact: "environment" };
}

const default_videoConstraints: IVideoConstraints = {
  width: 220,
  height: 200,
  facingMode: { exact: "environment" },
};

export interface ICameraProps {
  onCapture: (imageSource: string) => void;
}

const Camera = (params: ICameraProps) => {
  const webcamRef = React.useRef<any>(null);

  const [showCamera, setShowCamera] = useState(false);

  const capture = React.useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      params.onCapture(imageSrc);
    }
  }, [webcamRef]);

  const [cameraFacingMode, setCameraFacingMode] = useState<IVideoConstraints>(
    default_videoConstraints
  );

  const setCameraFacingModeClicked = () => {
    setCameraFacingMode({
      width: default_videoConstraints.width,
      height: default_videoConstraints.height,
      facingMode:
        cameraFacingMode.facingMode === "user"
          ? { exact: "environment" }
          : "user",
    });
  };

  const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    // styles: { root: { width: 300 } },
  };

  function _onCameraToggleChange(
    ev: React.MouseEvent<HTMLElement>,
    checked?: boolean
  ) {
    setShowCamera(!!checked);
  }

  return (
    <Stack {...columnProps} horizontalAlign="center">
      <Toggle
        label="Attach a picture?"
        inlineLabel
        onChange={_onCameraToggleChange}
      />
      <RenderIf condition={!!showCamera}>
        <div>
          <div className="CaptureButtonContainer">
            <Stack horizontalAlign="center">
              <Webcam
                audio={false}
                height={200}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={220}
                videoConstraints={cameraFacingMode}
              />
              <IconButton
                iconProps={{ iconName: "Camera" }}
                className="CaptureButtonStyle"
                onClick={capture}
              />
              <Stack horizontal>
                <ActionButton
                  iconProps={{ iconName: "RepeatAll" }}
                  onClick={setCameraFacingModeClicked}
                >
                  Reverse camera
                </ActionButton>
              </Stack>
            </Stack>
          </div>
        </div>
      </RenderIf>
    </Stack>
  );
};

export default Camera;
